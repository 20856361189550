import { reactive, toRefs, readonly, computed } from 'vue'
import { allGeo, allUniverses } from '@/utils/CONF'
import { http, extendWithAuthorization } from '@/http'
import { useToast } from 'vue-toastification'

const toast = useToast()

const state = reactive({
  geography: undefined,
  nomenclature: undefined
})

export default function() {
  const getParams = computed(() => state)

  const nomenclatureLevels = computed(() => {
    const levels = []
    let currentLevel = state.nomenclature || {}

    if (currentLevel.nomLevel) levels.push(currentLevel.nomLevel)

    while (currentLevel.childrenKey) {
      currentLevel = currentLevel[currentLevel.childrenKey][0] || {}
      levels.push(currentLevel.nomLevel)
    }

    return levels
  })

  const geoLevels = computed(() => {
    const levels = []
    let currentLevel = state.geography || {}

    if (currentLevel.geoLevel) levels.push(currentLevel.geoLevel)

    while (currentLevel.childrenKey) {
      currentLevel = currentLevel[currentLevel.childrenKey][0] || {}
      levels.push(currentLevel.geoLevel)
    }

    return levels
  })

  const nomenclatureByLevel = computed(() => level => {
    const levelDepth = nomenclatureLevels.value.indexOf(level)

    if (levelDepth === 0) return [state.nomenclature]
    return levelDepth > 0 ? getChildren(state.nomenclature, levelDepth) : []
  })

  const geoByLevel = computed(() => level => {
    const levelDepth = geoLevels.value.indexOf(level)
    if (levelDepth === 0) return [state.geography]
    return levelDepth > 0 ? getChildren(state.geography, levelDepth) : []
  })

  const getNomenclatureDepth = computed(() => level => nomenclatureLevels.value.indexOf(level))

  const getGeoDepth = computed(() => level => geoLevels.value.indexOf(level))

  const getFormat = computed(() => geo => {
    const geoWithChildren = geoByLevel(geo.geoLevel).find(g => g[g.geoLevel] === geo[geo.geoLevel])
    const formats =
      geo.geoLevel === 'site' ? [geoWithChildren.siteFormat] : getChildren(geoWithChildren, geoLevels.value.length).map(site => site.siteFormat)

    return [...new Set(formats)]
  })

  const getContracts = computed(() => geo => {
    const geoWithChildren = geoByLevel(geo.geoLevel).find(g => g[g.geoLevel] === geo[geo.geoLevel])
    const contracts =
      geo.geoLevel === 'site' ? [geoWithChildren.siteCurrentContract] : getChildren(geoWithChildren, geoLevels.value.length).map(site => site.siteCurrentContract)

    return [...new Set(contracts)]
  })

  const isLastGeoLevel = computed(() => level => getGeoDepth.value(level) === geoLevels.value.length - 1)

  const isLastNomenclatureLevel = computed(() => level => getNomenclatureDepth.value(level) === nomenclatureLevels.value.length - 1)

  const isMarketLevel = computed(() => level => getNomenclatureDepth.value(level) === nomenclatureLevels.value.length - 2)

  const getNomItemByTypeAndLevelAndId = ({ type, level, id }) => {
    const nomByLevel = type === 'geo' ? geoByLevel.value(level) : type === 'nom' ? nomenclatureByLevel.value(level) : []
    return nomByLevel.find(nomItem => +nomItem[level] === +id)
  }

  const setGeography = geography => {
    state.geography = geography
  }

  const setNomenclature = nomenclature => {
    state.nomenclature = nomenclature
  }

  const fetchSites = async() => {
    if (state.geography) return Promise.resolve(state.geography)
    let sites = []
    try {
      const extendedHttp = await extendWithAuthorization(http)
      sites = await extendedHttp
        .get('referential/geography')
        .json()
    } catch (error) {
      toast.error("Une erreur est survenue lors du chargement des sites")
      console.error(error)
    }

    setGeography({ ...allGeo, [allGeo.childrenKey]: sites })
    return Promise.resolve(sites)
  }

  const fetchNomenclature = async() => {
    if (state.nomenclature) return Promise.resolve(state.nomenclature)
    let nomenclature = []
    try {
      const extendedHttp = await extendWithAuthorization(http)
      nomenclature = await extendedHttp
        .get('referential/nomenclature')
        .json()
    } catch (error) {
      toast.error("Une erreur est survenue lors du chargement de la nomenclature")
      console.error(error)
    }

    setNomenclature({ ...allUniverses, [allUniverses.childrenKey]: nomenclature })
    return Promise.resolve(nomenclature)
  }

  return {
    ...toRefs(readonly(state)),
    getParams,
    nomenclatureLevels,
    geoLevels,
    nomenclatureByLevel,
    geoByLevel,
    getNomenclatureDepth,
    getGeoDepth,
    getFormat,
    getContracts,
    isLastGeoLevel,
    isLastNomenclatureLevel,
    isMarketLevel,
    getNomItemByTypeAndLevelAndId,
    setGeography,
    setNomenclature,
    fetchSites,
    fetchNomenclature
  }
}

const getChildren = (nom, maxDepth, depth = 0) => {
  if (nom.childrenKey && depth < maxDepth) return nom[nom.childrenKey].map(n => getChildren(n, maxDepth, depth + 1)).flat()
  return [nom]
}
