<template>
  <div :class="['nomenclature-level', { 'light-mode': lightMode }]">
    <div v-if="!lightMode" class="level">
      <span v-if="level">
        {{ $t(`geoNomSelection.${geo ? 'geographic' : 'nomenclature'}.levels.${level}`) }}
      </span>
      <span v-else>{{ $t('geoNomSelection.noElementsInFav') }}</span>
    </div>

    <div class="children">
      <template v-for="(nom,i) in values">
        <div v-if="isLastLevel || (nom[nom.childrenKey] && nom[nom.childrenKey].length)"
             :key="`${nom[fieldLevel]}:${nom[nom[fieldLevel]]}`"
             :class="{
               child: true,
               displayed: nom[nom[fieldLevel]] === displayed[nom[fieldLevel]],
               'not-selectable': !selectable, 'show-fav': (isLastLevel && geo) || isMarketLevel(level)
             }">
          <div v-if="selectable" class="check">
            <v-checkbox :disabled="!selectable" :value="selectable && selectedIds.includes(nom[nom[fieldLevel]])" @input="$emit('select', nom)" />
          </div>
          <div v-if="selectable && (isLastLevel && geo || isMarketLevel(level))" class="favorites">
            <v-svg :key="i"
                   h="1.5rem"
                   :name="getFavoriteTypeOfItem(nomenclatureName, nom) ? `${getFavoriteTypeOfItem(nomenclatureName, nom)}-active` : 'star'"
                   @click="onClickAddOrUpdateFavorite(nom)" />
          </div>
          <div class="infos" @click="$emit('display', nom)">
            <div class="label"> {{ nom[`${nom[fieldLevel]}Label`] }}</div>
            <div class="details grey">
              {{ getChildrenLabels(nom) }}
            </div>
          </div>
          <div class="chevron" @click="$emit('display', nom)">
            <v-svg v-if="nom.childrenKey" h="2rem" name="chevron-right" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import useContextStore from '@/store/context.store'
  import useReferentialStore from '@/store/referential.store'

  export default {
    name: 'NomenclatureLevel',
    props: {
      values: {
        type: Array,
        default: () => []
      },
      nomLevel: {
        type: String,
        default: ''
      },
      displayed: {
        type: Object,
        default: () => ({})
      },
      selectedIds: {
        type: Array,
        default: () => []
      },
      selectable: {
        type: Boolean,
        default: true
      },
      geo: {
        type: Boolean,
        default: false
      },
      lightMode: {
        type: Boolean,
        default: false
      }
    },
    emits: ['display', 'select'],
    setup(props) {
      const referentialStore = useReferentialStore()
      const contextStore = useContextStore()
      const { isLastGeoLevel, isLastNomenclatureLevel, isMarketLevel } = referentialStore
      const { addOrUpdateFavorite, getFavoriteTypeOfItem } = contextStore

      const nomenclatureName = computed(() => props.geo ? 'geography' : 'nomenclature')
      const fieldLevel = computed(() => props.geo ? 'geoLevel' : 'nomLevel')
      const level = computed(() => props.values.length ? (props.values[0] || {})[fieldLevel.value] : null)
      const isLastLevel = computed(() => props.geo ? isLastGeoLevel.value(level.value) : isLastNomenclatureLevel.value(level.value))

      const getChildrenLabels = nom => {
        const children = nom[nom.childrenKey]
        if (!children) return ''
        return children.map(n => n[`${ n[fieldLevel.value] }Label`]).join(', ')
      }

      const onClickAddOrUpdateFavorite = item => {
        if (props.geo) addOrUpdateFavorite(nomenclatureName.value, { site: item.site, siteLabel: item.siteLabel, siteSid: item.siteSid })
        else addOrUpdateFavorite(nomenclatureName.value, { market: parseInt(item.market), marketLabel: item.marketLabel, marketSid: item.marketSid })
      }

      return { fieldLevel, level, isLastLevel, isMarketLevel, getChildrenLabels, getFavoriteTypeOfItem, onClickAddOrUpdateFavorite, nomenclatureName }
    }
  }
</script>

<style lang="scss" scoped>
  .nomenclature-level {
    width: 24rem;
    flex-shrink: 0;
    .level {
      @extend %primary-font--bold;
      font-size: $font-size-title;
      margin: 0 0 1.6rem 1.6rem;
    }
    .children {
      border-right: 1px solid #A6A6A6;
      height: 40rem;
      max-height: 65vh;
      overflow-y: auto;

      .favorites {
        .v-svg {
          cursor: pointer;
          margin-right: 0.5rem;
        }
      }

      .child {
        padding: 0.8rem;
        margin: 0 1.6rem;
        display: grid;
        grid-template-columns: 15% 75% 10%;
        position: relative;
        align-items: center;
        border-bottom: 1px solid #A6A6A6;

        &.show-fav { grid-template-columns: 10% 10% 70% 10%; }

        &.not-selectable {
          grid-template-columns: 85% 15%;
        }

        &.displayed,
        &:hover {
          background-color: hsla(var(--app-color-hsla), 0.1);
        }
        .infos {
          cursor: pointer;
          height: 100%;
          .label {
            @extend %secondary-font--bold;
            font-size: $font-size-small;
          }
          .details {
            font-size: $font-size-tiny;
            @extend %secondary-font;
            color: rgba(166, 166, 166, 1);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .number-selected {
          position: absolute;
          right: 1.6rem;
          bottom: 0.1rem;
          @extend %primary-font--bold;
          font-size: 1rem;
          white-space: nowrap;
          color: var(--app-color);
        }
      }
    }

    &.light-mode {
      .children {
        border-right: none;
        .child {
          border-bottom: none;
          margin: 0 5px;
        }
      }
    }
  }
</style>
