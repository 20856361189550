<template>
  <div class="background" />
  <div class="coefficient-popup">
    <v-svg h="0.6rem" name="cross" class="close-cross" @click="$emit('close')" />
    <div class="title">{{ $t('coefficientPopup.title') }}</div>
    <div class="stepper">
      <div class="bullets">
        <template v-for="i of 4" :key="i">
          <div :class="[{ active: popupStep === i - 1 }, 'bullet']">{{ i }}</div>
          <div v-if="i < 4" class="line" />
        </template>
      </div>
      <div class="labels">
        <div v-for="(step, i) of steps" :key="i" :class="[{ active: popupStep === i }, 'text']">{{ step }}</div>
      </div>
    </div>
    <div v-show="popupStep === 0" class="coefficient-inputs">
      <div class="date-choice row-item">
        <div class="label">{{ $t('coefficientPopup.dateChoice') }}</div>
        <date-picker-multiple-days :selected-dates="luxonDates" @select="data.dates = $event" />
      </div>

      <div class="coefficient-type row-item">
        <div class="label">{{ $t('coefficientPopup.coefficientType') }}</div>
        <div class="select">
          <div v-for="(coefficientType, i) in coefficientTypes"
               :key="i"
               :class="[{ active: data.coefficientType === i + 1 }, 'item']"
               @click="data.coefficientType = i + 1">
            <div class="type-title">
              <v-svg h="1.5rem" :name="coefficientType" />
              {{ $t(`coefficientPopup.coefficientTypes.${coefficientType}`) }}
            </div>
            <div class="type-description">
              {{ $t(`coefficientPopup.coefficientTypes.${coefficientType}Desc`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="values">
        <div class="coefficient-value row-item">
          <div class="label">
            {{ data.coefficientType !== 3 ? $t('coefficientPopup.minValue') : $t('coefficientPopup.limited.minValue') }}
          </div>
          <div class="coefficient-value-input">
            <div class="min" @click="data.minValue = data.minValue > 0 ? Math.round((data.minValue - 0.01) * 100) / 100 : 0">-</div>
            <input v-model="data.minValue" type="number" min="0" max="5">
            <div class="max" @click="data.minValue = data.minValue < 5 ? Math.round((data.minValue + 0.01) * 100) / 100 : 5">+</div>
          </div>
        </div>

        <div v-if="data.coefficientType === 3" class="coefficient-value row-item value-max">
          <div class="label">{{ $t('coefficientPopup.limited.maxValue') }}</div>
          <div class="coefficient-value-input">
            <div class="min" @click="data.maxValue = data.maxValue > 0 ? Math.round((data.maxValue - 0.01) * 100) / 100 : 0">-</div>
            <input v-model="data.maxValue" type="number" min="0" max="5">
            <div class="max" @click="data.maxValue = data.maxValue < 5 ? Math.round((data.maxValue + 0.01) * 100) / 100 : 5">+</div>
          </div>
        </div>
      </div>
    </div>

    <geo-selection v-show="popupStep === 1"
                   :formats="selectedFormats"
                   :contracts="selectedContracts"
                   :light-mode="true"
                   @input="data.geo = $event" />

    <nom-selection v-show="popupStep === 2"
                   :light-mode="true"
                   :unselectable-levels="['univers']"
                   @input="data.nom = $event" />

    <coeff-popup-summary v-show="popupStep === 3" :data="data" :coefficient-types="coefficientTypes" />
    <div class="footer">
      <div v-if="popupStep > 0" class="go-back button" @click="popupStep = popupStep - 1">
        <div class="btn-label">{{ $t('coefficientPopup.goBack') }}</div>
        <v-svg h="0.9rem" name="undo" />
      </div>
      <div class="close button" @click="$emit('close')">
        <div class="btn-label">{{ $t('coefficientPopup.close') }}</div>
        <v-svg h="0.6rem" name="cross" />
      </div>
      <div :class="[loading ? 'disable' : 'validate', 'button']" @click="goToNextStep()">
        <div class="btn-label">{{ $t(`coefficientPopup.${popupStep !== 3 ? 'next' : 'validate'}`) }}</div>
        <v-svg v-if="!loading" h="0.8rem" name="validate" />
        <v-svg v-else h="1.2rem" name="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
  import { extendWithAuthorization, http } from '@/http'
  import { getCategoryInNom, getSitesInGeo } from '@/utils/indicators.utils'
  import { DateTime } from 'luxon'
  import { computed, inject, ref } from 'vue'
  import { useToast } from 'vue-toastification'
  import CoeffPopupSummary from './coefficientPopup/CoeffPopupSummary'

  export default {
    name: 'CoefficientPopup',
    components: { CoeffPopupSummary },
    emits: ['close', 'countUpdated'],
    setup(props, { emit }) {
      const toast = useToast()

      const initData = {
        coefficientType: 1,
        minValue: 1,
        maxValue: 5,
        dates: [],
        geo: undefined,
        nom: undefined
      }

      const data = ref({ ...initData })

      const luxonDates = computed(() => data.value.dates.slice().map(date => DateTime.fromISO(date)))

      const coefficientTypes = ref(['multiplier', 'forced', 'limited'])

      const selectedFormats = ref([])
      const selectedContracts = ref(['INT', 'FRA'])

      const userInfos = inject('userInfos')

      const loading = ref(false)

      const popupStep = ref(0)

      const steps = ref(['Coefficient', 'Geographie', 'Nomenclature', 'Récapitulatif'])

      const goToNextStep = async() => {
        if (loading.value) return

        const { coefficientType, minValue, maxValue, dates } = { ...data.value }

        if (popupStep.value === 0) {
          if (!dates.length) return validationError('Veuillez selectionner une ou plusieurs date(s)')
          if (typeof minValue !== 'number' || minValue < 0 || minValue > 5) return validationError('Valeur coefficient incorrecte. Veuillez saisir un chiffre entre 0 et 5')
          if (typeof maxValue !== 'number' || maxValue < 0 || maxValue > 5) return validationError('Valeur coefficient incorrecte. Veuillez saisir un chiffre entre 0 et 5')
          if (maxValue <= minValue) return validationError('Valeur coefficient incorrecte. La borne max doit être supérieur à la borne min')
          if (minValue > maxValue) return validationError('Valeur coefficient incorrecte. La borne min doit être inférieur à la borne max')
        }

        if (popupStep.value === 1 && !data.value.geo?.length) return validationError('Veuillez selectionner un ou plusieurs site(s)')

        if (popupStep.value === 2 && !data.value.nom?.length) return validationError('Veuillez selectionner un ou plusieurs élément(s) de nomenclature')

        if (popupStep.value < 3) return popupStep.value++

        const sites = getSitesInGeo(data.value.geo)
        const categories = getCategoryInNom(data.value.nom)

        const json = { sites, categories, coefficientType, minValue, maxValue, dates, userCode: userInfos?.employeeNumber }

        loading.value = true

        const extendedHttp = await extendWithAuthorization(http)
        await extendedHttp
          .post(`warehouse/coefficient`, { json })
          .json()
          .then(() => {
            emit('countUpdated')
            toast.success('Coefficient ajouté avec succès')
          })
          .catch(err => {
            toast.error('Une erreur est survenue lors de l\'ajout de votre coefficient')
            console.error(err)
          })
        loading.value = false
      }

      const validationError = msg => {
        loading.value = false
        return toast.error(msg)
      }

      const reset = () => data.value = { ...initData }

      return {
        data,
        popupStep,
        steps,
        luxonDates,
        coefficientTypes,
        selectedFormats,
        selectedContracts,
        goToNextStep,
        reset,
        loading
      }
    }
  }
</script>

<style scoped lang="scss">
  .background {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
  }

  .coefficient-popup {
    z-index: 31;
    position: fixed;
    left: 50%;
    top: 50%;
    height: 90vh;
    width: 80vw;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 1);
    padding: 1rem 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    overflow-y: auto;

    .close-cross {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
    }

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-title;
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }

    .stepper {
      display: grid;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: $font-size-small;
      margin-bottom: 1rem;
      @extend %primary-font;

      .bullets {
        display: flex;
        align-items: center;
        justify-content: center;

        .bullet {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #808080;
          color: white;
          margin: 0 auto;

          &.active { background-color: var(--app-color); }
        }

        .line {
          height: 1px;
          background-color: #808080;
          width: 100px;
          margin: 0 0.5rem;
        }
      }

      .labels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -2rem 0 -1rem;

        .text {
          color: #808080;
          text-align: center;
          margin-top: 0.5rem;

          &.active {
            color: black;
            @extend %primary-font--bold;
          }
        }
      }
    }

    .coefficient-inputs {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      .values {
        display: flex;
        align-items: center;

        .value-max { margin-left: 1rem; }
      }

      .row-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: $font-size-small;

        .label {
          margin-bottom: 0.5rem;
          font-size: $font-size-title;
          @extend %primary-font--bold;
        }
      }

      .date-choice {
        width: 100%;

        .date-picker-multiple-days {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      user-select: none;
      margin-top: 1rem;

      .item {
        display: flex;
        flex-direction: column;
        width: 30%;
        align-items: center;
        padding: 0.5rem 3rem;
        cursor: pointer;
        border-radius: 8px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

        .type-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font-size-title;
          @extend %primary-font--bold;

          .v-svg { color: var(--app-color); }
        }

        .type-description {
          margin-top: 0.5rem;
        }

        &.active {
          background: var(--app-color);
          color: white;
          border: none;

          .v-svg { color: white; }
        }

        .v-svg { margin-right: 0.5rem; }
      }
    }

    .coefficient-value-input {
      display: flex;
      align-items: center;

      .min, .max, input[type='number'] {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33px;
        width: 33px;
        border: 1px solid #A6A6A6;
        border-left: none;
        cursor: pointer;
        user-select: none;
        @extend %secondary-font--bold;
      }

      .min {
        border-radius: 7px 0 0 7px;
        border-left: 1px solid #A6A6A6;
      }

      .max { border-radius: 0 7px 7px 0; }

      input[type='number'] {
        outline: none;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .geo-selection, .nom-selection {
      position: relative;
      top: 0;
      left: 0;
      height: 74%;
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .footer {
      margin: 1rem 0 0 auto;
      display: flex;
      align-items: center;
      @extend %secondary-font--bold;
      font-size: $font-size-small;

      .button {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        cursor: pointer;
        margin-left: 2rem;

        &.disable {
          cursor: default;
          background: #C4C2C2;
          color: white;
          border-radius: 5px;
        }

        &.go-back, &.close {
          border: 1px solid #808080;
          color: #808080;
          border-radius: 5px;
        }

        &.validate {
          background: var(--app-color);
          color: white;
          border-radius: 5px;
        }

        .svg-cross { margin-top: -6px; }

        .v-svg { margin-left: 0.5rem; }
      }
    }

    &::-webkit-scrollbar {
      background: none;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--grey-light);
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
</style>
