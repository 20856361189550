import { globalProperties } from '@/main'
import router from '@/router'
import db from '@/utils/firestore'
import { doc, getDoc, setDoc } from 'firebase/firestore'

import { DateTime } from 'luxon'
import { computed, reactive, readonly, toRefs } from 'vue'
import { favoritesTypes } from '@/utils/CONF'

const userDocRef = doc(db, `USERS/${globalProperties.user.employeeNumber}`)

const blankFavorites = { star: [], label: [], filter: [], shipping: [], bookmark: [] }

const now = DateTime.local()

const state = reactive({
  period: {
    weekNumber: now.weekNumber,
    year: now.year
  },
  geography: [],
  nomenclature: [],
  favorites: {
    geography: { ...blankFavorites },
    nomenclature: { ...blankFavorites }
  }
})

export default function() {
  const getParams = computed(() => state)

  const getPeriodStartDateEndDate = computed(() => {
    const d = DateTime.fromObject({ weekYear: state.period.year, weekNumber: state.period.weekNumber })
    return { startDate: d.startOf('week').toISODate(), endDate: d.endOf('week').toISODate() }
  })

  const setSelectedPeriod = period => {
    state.period = period
    const dates = getPeriodStartDateEndDate.value
    router.push({ query: { ...router.currentRoute.value.query, startDate: dates.startDate, endDate: dates.endDate } })
  }

  const setSelectedGeo = geo => {
    if (geo && geo.length !== 0) {
      router.push({ query: { ...router.currentRoute.value.query, geoLevel: geo[0].geoLevel, geoId: geo[0].site } })
    } else {
      delete router.currentRoute.value.query.geoLevel
      delete router.currentRoute.value.query.geoId
    }
    state.geography = geo
  }

  const setSelectedNom = nom => {
    if (nom && nom.length !== 0) {
      router.push({
        query: {
          ...router.currentRoute.value.query,
          nomLevel: nom[0].nomLevel,
          nomId: nom[0][nom[0].nomLevel]
        }
      })
    } else {
      delete router.currentRoute.value.query.nomLevel
      delete router.currentRoute.value.query.nomId
    }
    state.nomenclature = nom
  }

  const getSelectedFavorites = async() => {
    const docSnap = await getDoc(userDocRef)

    if (docSnap.exists()) {
      const userData = docSnap.data() ?? {}
      if (userData.favorites) state.favorites = userData.favorites
    }
  }

  const setSelectedFavorites = favorites => {
    state.favorites = favorites
    setDoc(userDocRef, { favorites: state.favorites }, { merge: true })
  }

  const addOrUpdateFavorite = (key, item) => {
    const tmpFavorites = JSON.parse(JSON.stringify(state.favorites))
    const itemKey = key === 'nomenclature' ? 'market' : 'site'

    const prevFavType = getFavoriteTypeOfItem(key, item)
    let nextFavType = 'star'

    if (prevFavType) {
      nextFavType = favoritesTypes?.[favoritesTypes.findIndex(type => type === prevFavType) + 1] ?? null
      const favIndex = tmpFavorites[key][prevFavType].findIndex(fav => fav[itemKey] === item[itemKey])
      tmpFavorites[key][prevFavType].splice(favIndex, 1)
    }

    if (nextFavType) tmpFavorites[key][nextFavType].push(item)

    setSelectedFavorites(tmpFavorites)
  }

  const getFavoriteTypeOfItem = (key, item) => {
    const itemKey = key === 'nomenclature' ? 'market' : 'site'

    for (const favoriteType in state.favorites[key]) {
      if (state.favorites[key][favoriteType].findIndex(favItem => parseInt(favItem[itemKey]) === parseInt(item[itemKey])) > -1) return favoriteType
    }
    return undefined
  }

  const getFavoritesByTypes = (key, types) => types.reduce((acc, type) => [...state.favorites[key][type], ...acc], [])

  return {
    ...toRefs(readonly(state)),
    getParams,
    getPeriodStartDateEndDate,
    setSelectedPeriod,
    setSelectedGeo,
    setSelectedNom,
    getSelectedFavorites,
    setSelectedFavorites,
    getFavoriteTypeOfItem,
    addOrUpdateFavorite,
    getFavoritesByTypes
  }
}
