<template>
  <div class="background" />
  <div class="canceled-dr-popup">
    <loader v-if="loading" />
    <template v-else>
      <div class="header">
        <div class="title">{{ $t('canceledDrPopup.title') }}</div>
        <date-picker-week :selected-week="selectedPeriod" @select="updatePeriod($event)" />
        <v-svg h="0.8rem" name="cross" class="close-cross" @click="$emit('close')" />
      </div>
      <div class="container">
        <div class="canceled-dr-array">
          <div class="th">Magasins</div>
          <div class="th">Date d'ouverture</div>
          <div class="th">Date de calcul du DR</div>
          <div class="th">Jour de reference</div>
          <template v-for="(row, i) in rows" :key="i">
            <template v-for="(openingDate, j) in row.openingDates" :key="j">
              <div :class="['td site first-cell-of-line', { 'first-line': i === 0 }]">
                {{ row.site }}
                <div class="bold">{{ ` • ${ row.siteLabel }` }}</div>
              </div>
              <div class="td">{{ $filters.formatDate(openingDate.specialOpeningDate, 'dd LLL y') }}</div>
              <div class="td">{{ $filters.formatDate(openingDate.calculatedDay, 'dd LLL y') }}</div>
              <div :class="['td last-cell-of-line', { 'first-line': i === 0 }]">{{ $filters.formatKeyDay(openingDate.keyDay) }}</div>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { extendWithAuthorization, http } from '@/http'
  import { DateTime } from 'luxon'
  import { ref } from 'vue'
  import { useToast } from 'vue-toastification'
  import useContextStore from '@/store/context.store'

  export default {
    name: 'CanceledDrPopup',
    emits: ['close'],
    setup() {
      const toast = useToast()

      const loading = ref(false)
      const rows = ref([])

      const contextStore = useContextStore()
      const { period, getPeriodStartDateEndDate } = contextStore

      const selectedPeriod = ref({ ...period.value })

      const updatePeriod = period => {
        selectedPeriod.value = period
        const d = DateTime.fromObject({ weekYear: period.year, weekNumber: period.weekNumber })
        fetchRows(d.startOf('week').toISODate(), d.endOf('week').toISODate())
      }

      const fetchRows = async(startDate, endDate) => {
        loading.value = true

        const extendedHttp = await extendWithAuthorization(http)
        await extendedHttp
          .get('indicators/double-restocking', { searchParams: { startDate, endDate, restocking: 'N' } })
          .json()
          .then(res => rows.value = res)
          .catch(err => {
            toast.error('Une erreur est survenue lors du chargement des doubles réappro')
            console.error(err)
          })
        loading.value = false
      }

      fetchRows(getPeriodStartDateEndDate.value?.startDate, getPeriodStartDateEndDate.value?.endDate)

      return { rows, loading, updatePeriod, selectedPeriod }
    }
  }
</script>

<style scoped lang="scss">
  .background {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 30;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
  }

  .canceled-dr-popup {
    z-index: 31;
    position: fixed;
    left: 50%;
    top: 50%;
    height: 90vh;
    width: 60vw;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 1);
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    border-radius: 16px;

    .loader { height: 100%; }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.5rem;
      margin-bottom: 1rem;

      .title {
        @extend %primary-font--bold;
        font-size: $font-size-big;
      }

      .close-cross {
        cursor: pointer;
        margin-top: -0.8rem;
      }
    }

    .container {
      overflow-y: auto;
      padding: 0 1.5rem;

      .canceled-dr-array {
        padding: 1rem;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        align-items: flex-end;
        font-size: $font-size-medium;
        border: 1px solid #A6A6A6;
        border-radius: 16px;

        .th {
          @extend %primary-font--bold;
          border-bottom: 1px solid #333333;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 12;
          background-color: white;
        }

        .td {
          display: flex;
          align-items: center;
          padding: 0.5rem;

          &.site {
            display: flex;
            align-items: center;
            color: #E65858;

            .bold {
              @extend %primary-font--bold;
              margin-left: 0.2rem;
            }
          }
        }

        //each odd line
        @for $i from 5 through 8 {
          .td:nth-child(8n + #{$i}) {
            background-color: #F6EEE9;

            &.first-cell-of-line {
              border-radius: 10px 0 0 10px;

              &.first-line { border-radius: 0 0 0 10px; }
            }

            &.last-cell-of-line {
              border-radius: 0 10px 10px 0;

              &.first-line { border-radius: 0 0 10px 0; }
            }
          }
        }
      }
    }
  }
</style>
