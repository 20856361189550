<template>
  <div class="background" />
  <div class="without-dr-popup">
    <loader v-if="loading" />
    <template v-else>
      <div class="header">
        <div class="title">{{ $t('withoutDrPopup.title') }}</div>
        <date-picker-day :selected-period="selectedDate" @select="selectDate = $event"/>
        <v-svg h="0.8rem" name="cross" class="close-cross" @click="$emit('close')" />
      </div>
      <div class="container">
        <div class="without-dr-array">
          <div class="th">
            <span>{{ $t('withoutDrPopup.siteTitle') }} ({{ rows.length }})</span>
            <div class="right">
              <div class="favorites-filter">
                <v-svg v-for="(type, i) in favTypes"
                       :key="`fav-filter-${i}`"
                       :class="{ active: activeFavoriteTypes.includes(type) }"
                       h="1.5rem"
                       :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
                       @click="onFavoriteFilterClicked(type)" />
              </div>
              <div class="search">
                <input v-model="searchTerm"
                       type="text"
                       class="input"
                       :placeholder="$t('geoNomSelection.geographic.placeholderResearch')">
                <v-svg h="1.5rem" name="loupe" />
              </div>
            </div>
          </div>
          <template v-for="(row, i) in filteredRows" :key="i">
              <div :class="['td site first-cell-of-line', { 'first-line': i === 0 }]">
                <v-svg :key="`icon-${i}`"
                       h="1rem"
                       :name="getFavoriteTypeOfItem('geography', { site: parseInt(row.site) }) ? `${getFavoriteTypeOfItem('geography', { site: parseInt(row.site) })}-active` : 'star'"
                       @click="addOrUpdateFavorite('geography', { site: parseInt(row.site), siteLabel: row.siteLabel, siteSid: row.siteSid })"
                />
                {{ row.site }}
                <div class="bold">{{ ` • ${ row.siteLabel }` }}</div>
              </div>
          </template>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { extendWithAuthorization, http } from '@/http'
import { DateTime } from 'luxon'
import { computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import useContextStore from '@/store/context.store'
import { favoritesTypes } from '@/utils/CONF'
import { removeAccents } from '@/utils/indicators.utils'

export default {
  name: 'WithoutDrPopup',
  emits: ['close'],
  setup() {
    const toast = useToast()

    const loading = ref(false)
    const rows = ref([])
    const searchTerm = ref('')

    const contextStore = useContextStore()
    const { getFavoriteTypeOfItem, getFavoritesByTypes, addOrUpdateFavorite } = contextStore

    const selectDate = ref(DateTime.now().toISODate())
    const selectedDate = computed(() => DateTime.fromISO(selectDate.value))

    const fetchRows = async() => {
      loading.value = true

      const extendedHttp = await extendWithAuthorization(http)
      const date = DateTime.fromISO(selectDate.value).toFormat('yyyy-LL-dd')
      await extendedHttp
        .get('indicators/double-restocking', { searchParams: { startDate: date,endDate: date, restocking: 'empty' } })
        .json()
        .then(res => rows.value = res)
        .catch(err => {
          toast.error('Une erreur est survenue lors du chargement des sites sans DR')
          console.error(err)
        })
      loading.value = false
    }

    watch(() => (selectDate.value), () => fetchRows())

   fetchRows()

    const activeFavoriteTypes = ref([])

    const favTypes = ref(favoritesTypes)

    const onFavoriteFilterClicked = type => {
      const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
      isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)
    }

    const filteredRows = computed(() => {
      let r = rows.value.slice()

      if (activeFavoriteTypes.value?.length) r = r.filter(row => getFavoritesByTypes('geography', activeFavoriteTypes.value).some(favoriteItem => parseInt(favoriteItem.site) === parseInt(row.site)))
      if (searchTerm.value?.length) r = r.filter(row => removeAccents(`${ row.site } - ${ row.siteLabel }`).includes(removeAccents(searchTerm.value)))

      return r
    })

    return {
      rows,
      loading,
      selectedDate,
      selectDate,
      searchTerm,
      favTypes,
      activeFavoriteTypes,
      onFavoriteFilterClicked,
      getFavoriteTypeOfItem,
      addOrUpdateFavorite,
      filteredRows }
  }
}
</script>

<style scoped lang="scss">
.background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}

.without-dr-popup {
  z-index: 31;
  position: fixed;
  left: 50%;
  top: 50%;
  height: 90vh;
  width: 60vw;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 1);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  .loader { height: 100%; }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    margin-bottom: 1rem;

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-big;
    }

    .close-cross {
      cursor: pointer;
      margin-top: -0.8rem;
    }
  }

  .container {
    overflow-y: auto;
    padding: 0 1.5rem;

    .without-dr-array {
      padding: 1rem;
      width: 100%;
      display: grid;
      //grid-template-columns: 1fr 1fr 1fr;
      align-items: flex-end;
      font-size: $font-size-medium;
      border: 1px solid #A6A6A6;
      border-radius: 16px;

      .th {
        display: flex;
        height: 2rem;
        padding-bottom: .5rem;
        @extend %primary-font--bold;
        border-bottom: 1px solid #333333;
        top: 0;
        left: 0;
        z-index: 12;
        background-color: white;

        .right {
          display: flex;
          .favorites-filter {
            display: flex;
            align-items: center;
            border-right: 2px solid #A6A6A6;
            border-left: 2px solid #A6A6A6;
            padding: 7px 12px;
            margin: 0 1.3rem;

            .v-svg {
              cursor: pointer;
              margin-right: 0.5rem;

            }
          }
          .search {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 7px;
            padding: 9px 12px;

            input[type='text'] {
              width: 100px;
              border: none;
              outline: none;
              border-radius: 8px;
              font-size: $font-size-medium;
              @extend %secondary-font--bold;

              &.error {
                border: 1px solid #FF3526;
              }
            }

            .label {
              font-weight: 600;
            }
          }
        }
      }

      .td {
        display: flex;
        align-items: center;
        padding: 0.5rem;

        .v-svg {
          cursor: pointer;
          margin-right: 0.2rem;
        }

        &.site {
          display: flex;
          align-items: center;
          color: #333333;

          .bold {
            @extend %primary-font--bold;
            margin-left: 0.2rem;
          }
        }
      }

      //each odd line
      @for $i from 2 through 2 {
        .td:nth-child(2n + #{$i}) {
          background-color: #F6EEE9;

          border-radius: 10px 10px 10px 10px;
          &.first-line { border-radius: 0 0 0 10px; }

          &.first-line { border-radius: 0 0 10px 0; }
        }
      }
    }
  }
}
</style>
