<!-- Created By Vasse Alexandre -->
<template>
  <div class="coeff-popup-summary">
    <div class="label">{{ $t('coefficientPopup.summary.coeffValue') }}</div>
    <div class="item">{{ data.coefficientType === 3 ? `Min: ${data.minValue} / Max: ${data.maxValue}` : data.minValue }}</div>
    <div class="label dates">Ma/Mes date(s) :</div>
    <div class="dates">
      <div v-for="(date, i) in dates" :key="i" class="item">{{ date }}</div>
    </div>
    <div class="label types">{{ $t('coefficientPopup.summary.coeffType') }}</div>
    <div class="types bordered">
      <div class="item">{{ $t(`coefficientPopup.coefficientTypes.${coefficientTypes[data.coefficientType - 1]}`) }}</div>
    </div>
    <div class="label geo">{{ $t('coefficientPopup.summary.geo') }}</div>
    <div class="geo list">
      <div v-for="(geo, i) in data.geo" :key="i" class="item">{{ $t(`geoNomSelection.geographic.levelsSummary.${geo.geoLevel}`) + ` ${geo[`${geo.geoLevel}Label`]}` }}</div>
    </div>
    <div class="label nom">{{ $t('coefficientPopup.summary.nom') }}</div>
    <div class="nom list">
      <div v-for="(nom, i) in data.nom" :key="i" class="item">{{ $t(`geoNomSelection.nomenclature.levelsSummary.${nom.nomLevel}`) + ` ${nom[`${nom.nomLevel}Label`]}` }}</div>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { computed } from 'vue'

  export default {
    name: 'CoeffPopupSummary',
    props: {
      data: {
        type: Object,
        default: () => ({})
      },
      coefficientTypes: {
        type: Array,
        default: () => (['multiplier', 'forced', 'limited'])
      }
    },
    setup(props) {
      const dates = computed(() => props.data.dates.slice().map(date => DateTime.fromISO(date).toFormat('EEE dd MMMM yyyy')))

      return {
        dates
      }
    }
  }
</script>

<style scoped lang="scss">
  .coeff-popup-summary {
    margin-top: 2rem;
    padding: 0 4rem 1rem 4rem;
    width: 100%;
    display: grid;
    grid-template-columns: 25% auto;
    row-gap: 2rem;

    .label {
      @extend %primary-font--bold;
      padding: 4px;
    }

    .dates, .geo, .nom, .types {
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.5rem;
    }

    .dates {
      .item { text-transform: capitalize; }
    }

    .types, .geo {
      margin-top: 1rem;
      padding-bottom: 1rem;
      position: relative;
    }

    .list {
      max-height: 125px;
      overflow-y: auto;
    }

    .bordered {
      &:after {
        width: 120%;
        height: 0.5px;
        position: absolute;
        bottom: -10px;
        left: -20%;
        z-index: 2;
        background-color: #A6A6A6;
        content: "";
      }
    }

    .item {
      padding: 4px;
      border: 1px solid #A6A6A6;
      font-size: $font-size-small;
      margin-right: 0.5rem;
      border-radius: 7px;
      width: fit-content;
    }
  }
</style>
