<template>
  <div id="fab-buttons" class="fab-buttons">
    <v-svg v-if="$filters.userIsAdmin() || $filters.userIsReam()"
           v-tooltip="$t('fabButtons.dateTooltip')"
           h="3rem"
           name="date-add-btn"
           @click="showShopOpeningPopup = true" />
    <v-svg v-if="$filters.userIsAdmin() || $filters.userIsReam()"
           v-tooltip="$t('fabButtons.coefTooltip')"
           h="3rem"
           name="coef-add-btn"
           @click="showCoefficientPopup = true" />
    <v-svg h="10rem" name="by-smart" />
    <div class="version">{{ packageVersion }}</div>
  </div>

  <shop-opening-popup v-if="showShopOpeningPopup" @close="disabledPopinAndReload(true)" @countUpdated="countUpdated()" />
  <coefficient-popup v-if="showCoefficientPopup" @close="disabledPopinAndReload()" @countUpdated="countUpdated()" />
</template>

<script>

  import { inject, ref } from 'vue'
  import { version } from '../../../../package.json'
  import CoefficientPopup from './CoefficientPopup'
  import ShopOpeningPopup from './ShopOpeningPopup'

  export default {
    name: 'FabButtons',
    components: { CoefficientPopup, ShopOpeningPopup },
    setup() {
      let showCoefficientPopup = ref(false)
      let showShopOpeningPopup = ref(false)
      let isUpdated = false

      const emitter = inject("emitter")

      const disabledPopinAndReload = isDoubleRestocking => {
        if(isUpdated) {
          isUpdated = false
          isDoubleRestocking ? emitter.emit('reloadDRData') : emitter.emit('reloadData')
        }
        showShopOpeningPopup.value = false
        showCoefficientPopup.value = false
      }

      const countUpdated = () => isUpdated = true

      const packageVersion = ref(version)

      return { showShopOpeningPopup, showCoefficientPopup, disabledPopinAndReload, countUpdated, packageVersion }
    }
  }
</script>

<style lang="scss" scoped>
  .fab-buttons {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .svg-date-add-btn, .svg-coef-add-btn {
      cursor: pointer;
    }

    .svg-by-smart { margin-top: 1rem; }

    .version {
      margin-top: 0.5rem;
      @extend %primary-font;
      font-size: $font-size-tiny;
    }
  }
</style>
