<template>
  <div :class="{ disabled, checkbox: true }" @click="!disabled && $emit('input', !value)">
    <v-svg v-if="value" :h="h" class="checked" name="checked" />
    <div v-else class="circle" :style="{ width: h, height: h }" />
  </div>
</template>

<script>
  export default {
    name: 'VCheckbox',
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      h: {
        type: String,
        default: '1.5rem'
      }
    },
    emits: ['input']
  }
</script>

<style lang="scss" scoped>
  .checkbox {
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .circle {
    border-radius: 50%;
    border: 2px solid rgba(128, 128, 128, 1);
  ;
  }

  .checked {
    color: var(--app-color);
  }
</style>
