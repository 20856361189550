export function isForecastOverview(overview) {
  return overview === 'coefficient'
}

export function isPercentOverview(overview) {
  return overview === 'evolutionPercentSalesQuantity'
}

export function isQuantityOverview(overview) {
  return overview === 'forecastSalesQuantity'
}

export function buildDisplayIndicator(indicator, overview) {
  return indicator === null ? isPercentOverview(overview) ? 0 : 1 : indicator
}

export function buildUnitIndicator(overview) {
  return isPercentOverview(overview) ? '%' : ''
}

export function buildNumberOfDecimalIndicator(overview) {
  return isForecastOverview(overview) ? 2 : 0
}

export function buildColorClassIndicator(forecastRow) {

  if(forecastRow.forecastType) {
    switch(forecastRow.forecastType) {
      case 1: return 'multiplier'
      case 2: return 'forced'
      case 3: return 'limited'
    }
  }
  if(forecastRow.doubleRestocking) return 'double-restocking'
  if(forecastRow.isManualValue) return 'user-value'
}

export function getSitesInGeo(geo, sites = []) {
  if (!geo.length) return sites

  const [, ...rest] = geo

  if (geo[0].geoLevel === 'site') return getSitesInGeo(rest, [geo[0].site, ...sites])
  else return getSitesInGeo([...rest, ...geo[0][geo[0].childrenKey]], sites)
}

export function getCategoryInNom(nom, categories = []) {
  if (!nom.length) return categories

  const [, ...rest] = nom

  if (nom[0].nomLevel === 'category') return getCategoryInNom(rest, [nom[0].category, ...categories])
  else return getCategoryInNom([...rest, ...nom[0][nom[0].childrenKey]], categories)
}

export function removeAccents(string = '') {
  return `${string}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}
