import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Nomenclature',
    component: () => import(/* webpackChunkName: "Nomenclature" */ '@/views/Nomenclature.vue'),
  },
  {
    path: '/geographic',
    name: 'Geographic',
    component: () => import(/* webpackChunkName: "Geographic" */ '@/views/Geographic.vue')
  },
  {
    path: '/double-restocking',
    name: 'DoubleRestocking',
    component: () => import(/* webpackChunkName: "DoubleRestocking" */ '@/views/DoubleRestocking.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
