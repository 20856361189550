<template>
  <div class="loader">
    <div class="loader__content">
      <img src="../../assets/svg/OptiCAM-logo.svg" class="loader__content__logo">
      <div class="loader__content__landscape">
        <div class="loader__content__landscape__mountain" />
        <div class="loader__content__landscape__hill" />
        <div class="loader__content__landscape__tree" />
        <div class="loader__content__landscape__tree" />
        <div class="loader__content__landscape__tree" />
        <div class="loader__content__landscape__rock" />
        <div class="loader__content__landscape__truck" />
        <div class="loader__content__landscape__wheels" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loader'
  }
</script>

<style scoped lang="scss">
  $color-hill: #3aad2e;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 55px);
    width: 100%;
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-image: linear-gradient(to bottom, #c5601b 75%, #565656 41%);
      border-radius: 50%;
      height: 35rem;
      overflow: hidden;
      width: 35rem;
      -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
      &__logo {
        z-index: 2;
        width: 20rem;
      }
      &__landscape {
        border-bottom: 3px solid #fff;
        height: 40%;
        position: relative;
        overflow: hidden;
        width: 600px;
        &__mountain {
          position: absolute;
          right: -900px;
          bottom: -20px;
          width: 2px;
          height: 2px;
          box-shadow: 0 0 0 50px #737373, 60px 50px 0 70px #565656, 90px 90px 0 50px #565656, 250px 250px 0 50px #757474, 290px 320px 0 50px #444343, 320px 400px 0 50px #565656;
          transform: rotate(130deg);
          animation: mtn 5s linear infinite;
        }
        &__hill {
          position: absolute;
          right: -900px;
          bottom: -50px;
          width: 400px;
          border-radius: 50%;
          height: 20px;
          box-shadow: 0 0 0 50px #158409, -20px 0 0 20px #158409, -90px 0 0 50px #158409, 250px 0 0 50px #158409, 290px 0 0 50px #158409, 620px 0 0 50px #158409;
          animation: hill 2s 2s linear infinite;
        }
        &__tree {
          position: absolute;
          height: 100px;
          width: 35px;
          bottom: 0;
          background: url('../../assets/svg/tree.svg') no-repeat;
        }
        &__rock {
          margin-top: -17%;
          height: 2%;
          width: 2%;
          bottom: -2px;
          border-radius: 20px;
          position: absolute;
          background: #ddd;
        }
        &__truck,
        &__wheels {
          transition: all ease;
          width: 85px;
          margin-right: -60px;
          bottom: 0;
          right: 50%;
          position: absolute;
          background: #eee;
        }
        &__truck {
          background: url('../../assets/svg/truck.svg') no-repeat;
          height: 60px;
          &:before {
            content: " ";
            position: absolute;
            width: 25px;
            box-shadow: -30px 28px 0 1.5px #fff, -35px 18px 0 1.5px #fff;
          }
        }
        &__wheels {
          background: url('../../assets/svg/wheels.svg') no-repeat;
          height: 15px;
          margin-bottom: 0;
        }
        &__tree {
          animation: tree 1.5s 0s linear infinite;
        }
        &__tree:nth-child(2) {
          animation: tree2 1s 0.15s linear infinite;
        }
        &__tree:nth-child(3) {
          animation: tree3 4s 0.05s linear infinite;
        }
        &__rock {
          animation: rock 4s -0.53s linear infinite;
        }
        &__truck {
          animation: truck 2s 0.08s ease infinite;
        }
        &__wheels {
          animation: truck 2s 0.001s ease infinite;
        }
        &__truck:before {
          animation: wind 1.5s 0s ease infinite;
        }
      }
      @keyframes tree {
        0% {
          transform: translate(1350px);
        }
        50% {}
        100% {
          transform: translate(-50px);
        }
      }
      @keyframes tree2 {
        0% {
          transform: translate(650px);
        }
        50% {}
        100% {
          transform: translate(-50px);
        }
      }
      @keyframes tree3 {
        0% {
          transform: translate(2750px);
        }
        50% {}
        100% {
          transform: translate(-50px);
        }
      }
      @keyframes rock {
        0% {
          right: -200px;
        }
        100% {
          right: 2000px;
        }
      }
      @keyframes truck {
        0% {}
        6% {
          transform: translateY(0px);
        }
        7% {
          transform: translateY(-6px);
        }
        9% {
          transform: translateY(0px);
        }
        10% {
          transform: translateY(-1px);
        }
        11% {
          transform: translateY(0px);
        }
        100% {}
      }
      @keyframes wind {
        0% {}
        50% {
          transform: translateY(3px)
        }
        100% {}
      }
      @keyframes mtn {
        100% {
          transform: translateX(-2000px) rotate(130deg);
        }
      }
      @keyframes hill {
        100% {
          transform: translateX(-2000px);
        }
      }
    }
  }
</style>
