<template>
  <div id="forbidden">
    <div class="logo-container">
      <img src="@/assets/logo.png" class="logo">
    </div>
    <div class="title">Tu n’es pas autorisé à accéder à cette application</div>
    <div class="content">
      <div v-if="!demanded" class="left">
        <p>
          Besoin de demander l’accès? <br>
          Décris ci-dessous pourquoi tu souhaiterais avoir accès à l’application.
        </p>
        <textarea v-model="message"
                  maxlength="500"
                  placeholder="Entre ta description (500 caractères max)"
                  cols="40"
                  rows="7" />
        <div class="button-container">
          <button class="button" @click="sendDemand"> <v-svg h="1.5em" name="checkmark" /> Demander l’accès </button>
        </div>
      </div>
      <div v-else class="left">
        <p> Ta demande d'habilitation a été envoyée.</p>
      </div>
      <div class="right">
        <img src="@/assets/img/id-verification.png" class="illustration">
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, inject, ref } from 'vue'
  import ky from 'ky'

  export default {
    name: 'Forbidden',
    setup() {
      const message = ref('')
      const demand = computed(() => ({ application: process.env.VUE_APP_APP_NAME, message: message.value }))
      const demanded = ref(false)

      const user = inject('user')
      const http = ky.create({ prefixUrl: process.env.VUE_APP_BACKEND_SMART, timeout: 60000 })

      const getDemand = async() => {
        const token = await user.getIdToken()
        const d =
          (await http
            .extend({ headers: { Authorization: `Bearer ${token}`, project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID } })
            .get(`authorization/demand`, { searchParams: { application: demand.value.application } })
            .json()) ?? {}
        demanded.value = !!d.timestamp
      }

      const sendDemand = async() => {
        const token = await user.getIdToken()
        await http
          .extend({ headers: { Authorization: `Bearer ${token}`, project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID } })
          .post(`authorization/demand`, { json: demand.value })
          .json()
        demanded.value = true
      }

      getDemand()

      return {
        message,
        demanded,
        sendDemand
      }
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

  #app {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #forbidden {
    width: 1100px;
    padding: 16px;
    overflow-y: auto;
    max-height: stretch;
    .logo-container {
      display: flex;
      @media screen and (max-width: 1100px) {
        justify-content: center;
      }
      .logo {
        max-height: 44px;
        max-width: 50vw;
      }
    }
    .title {
      font-family: Manrope;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      text-align: center;

      @media screen and (min-width: 1100px) {
        text-align: initial;
        line-height: 60px;
        font-size: 44px;
      }
    }
    .content {
      display: grid;
      @media screen and (min-width: 1100px) {
        grid-template-columns: 1fr 1fr;
      }
      gap: 32px;
      .left {
        p {
          color: #21ade0;
          font-family: Manrope;
          font-weight: bold;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
          @media screen and (min-width: 1100px) {
            text-align: initial;
          }
        }
        textarea {
          display: block;
          background-color: rgba(1, 12, 20, 0.05);
          border: none;
          resize: none;
          outline: none;
          padding: 16px;
          width: 100%;
          font-family: Manrope;
          font-size: 14px;
          border-radius: 8px;
          line-height: 19px;
        }
        .button-container {
          display: flex;
          justify-content: center;
          @media screen and (min-width: 1100px) {
            justify-content: flex-end;
          }
          .button {
            display: flex;
            align-items: center;
            background-color: #21ade0;
            border: none;
            cursor: pointer;
            outline: none;
            border-radius: 8px;
            padding: 8px 12px;
            font-family: Manrope;
            font-size: 14px;
            color: white;
            margin-top: 16px;
            .v-svg {
              margin-right: 0.6em;
            }
          }
        }
      }
      .right {
        .illustration {
          max-width: 100%;
          max-height: 400px;
        }
      }
    }
  }
</style>
