<template>
  <div class="date-picker-day">
    <div class="info-btn">
      <v-svg h="1.5rem" name="chevron-left" @click="shiftDay(-1)" />
      <div class="label" @click="togglePicker()">{{ selectedPeriodToString }}</div>
      <v-svg h="1.5rem" name="chevron-right" @click="shiftDay(1)" />
      <v-svg h="1.2rem" name="calendar" @click="togglePicker()" />
    </div>
    <div v-if="isOpen" class="picker">
      <div class="content">
        <v-svg h="0.8rem" name="cross" @click="isOpen = false" />
        <div class="navigation">
          <div class="month selector">
            <v-svg h="1.5rem" name="chevron-left" @click="shiftMonthOrYear({ month: -1 })" />
            <div class="month label">{{ date.toFormat('LLLL') }}</div>
            <v-svg h="1.5rem" name="chevron-right" @click="shiftMonthOrYear({ month: 1 })" />
          </div>
          <div class="year selector">
            <v-svg h="1.5rem" name="chevron-left" @click="shiftMonthOrYear({ year: -1 })" />
            <div class="year label">{{ date.year }}</div>
            <v-svg h="1.5rem" name="chevron-right" @click="shiftMonthOrYear({ year: 1 })" />
          </div>
        </div>
        <div class="calendar">
          <div v-for="(dayLetter, i) of daysLetters" :key="i" class="th day">{{ dayLetter }}</div>
          <div class="th week">N°</div>
          <div v-for="(day, i) in emptyCells"
               :key="`previousMonthDay#${day}`"
               :class="[{ first: i === 0 }, 'td prev-month-day']">
            <div class="number">{{ day }}</div>
          </div>
          <template v-for="(day, i) of date.daysInMonth" :key="i">
            <div :class="[{active: selectedPeriod.day === day }, 'td day']"
                 @click="selectDay(day)">
              <div class="number">{{ day }}</div>
            </div>
            <div v-if="(emptyCells.length + i + 1) % 7 === 0" class="td week">
              <div class="number">{{ date.set({ day }).weekNumber }}</div>
            </div>
          </template>
          <div v-for="day in daysOfNextMonth"
               :key="`nextMonthDay#${day}`"
               :class="[{ first: daysOfNextMonth === 7 && day === 1 }, 'td next-month-day']">
            <div class="number">{{ day }}</div>
          </div>
          <div class="td week last">
            <div class="number">{{ date.plus({ month: 1 }).weekNumber }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime, Info } from 'luxon'
  import { computed, ref } from 'vue'

  export default {
    name: 'DatePickerDay',
    props: {
      selectedPeriod: {
        type: Object,
        default: () => ({ ...DateTime.now() })
      }
    },
    emits: ['select'],
    setup(props, { emit }) {
      const isOpen = ref(false)

      const daysLetters = Info.weekdays('short').map(day => day[0].toUpperCase())

      const date = ref(DateTime.now().set({ day: 1 }))

      const daysOfPreviousMonth = computed(() => date.value.minus({ months: 1 }).daysInMonth)

      const emptyCells = computed(() => {
        const cells = []
        for (let i = date.value.weekday - 1; i > 0; i--) cells.push(daysOfPreviousMonth.value - i + 1)
        return cells
      })

      const daysOfNextMonth = computed(() => 7 - ((date.value.daysInMonth + emptyCells.value.length) % 7))

      const selectedPeriodToString = computed(() => `${ props.selectedPeriod.toFormat('EEE dd MMMM yyyy') } / Semaine ${ props.selectedPeriod.weekNumber }`)

      const shiftMonthOrYear = ({ year = 0, month = 0 }) => date.value = date.value.plus({ year, month })

      const shiftDay = day => emit('select', props.selectedPeriod.plus({ day }))

      const selectDay = day => emit('select', DateTime.fromObject({ day: day, month: date.value.month, year: date.value.year }))

      const togglePicker = () => isOpen.value = !isOpen.value

      return {
        isOpen,
        daysLetters,
        date,
        emptyCells,
        shiftMonthOrYear,
        daysOfNextMonth,
        selectDay,
        shiftDay,
        selectedPeriodToString,
        togglePicker
      }
    }
  }
</script>

<style scoped lang="scss">
  .date-picker-day {
    position: relative;
    width: 380px;

    .info-btn {
      text-transform: capitalize;
      position: relative;
      display: flex;
      align-items: center;
      color: var(--app-color);
      border: 1px solid rgba(158, 81, 33, 0.5);
      padding: 4px 9px 3px 5px;
      border-radius: 7px;
      font-size: 12px;
      width: 330px;

      .title {
        position: absolute;
        top: -10px;
        background-color: white;
        padding: 0 3px;
        @extend %secondary-font;
        font-size: $font-size-tiny;
      }

      .label {
        @extend %secondary-font--bold;
        font-size: $font-size-small;
        cursor: pointer;
      }

      .v-svg { cursor: pointer; }
    }

    .picker {
      position: absolute;
      margin: 15px 0 0 25px;
      width: 420px;
      background-color: white;
      border-radius: 16px;
      border: 1px solid #CFCFCF;
      z-index: 9999999;
      padding: 1rem;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::before {
        top: -22px;
        right: 126px;
        border-color: transparent transparent #CFCFCF transparent;
        border-width: 11px;
      }

      &::after {
        top: -19px;
        right: 126.9px;
        border-color: transparent transparent white transparent;
        border-width: 10px;
      }
    }

    .content {
      position: relative;
      height: 95%;
      width: 100%;
      border-radius: 16px;
      border: 1px solid #CFCFCF;

      .title {
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 0.5rem;
        background-color: white;
        font-size: $font-size-title;
        @extend %primary-font--bold;
      }

      .svg-cross {
        position: absolute;
        top: -8px;
        right: 12px;
        padding: 0 0.5rem;
        cursor: pointer;
        background-color: white;
      }

      .navigation {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1.5rem;
        @extend %secondary-font--bold;
        font-size: $font-size-medium;

        .selector {
          display: flex;
          align-items: center;

          .label { margin: 0 1rem; }
        }

        .v-svg { cursor: pointer; }

        .month { text-transform: capitalize; }
      }

      .calendar {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        padding: 1rem 1.2rem;
        gap: 0.5rem;
        position: relative;

        .th, .td {
          text-align: center;
          padding: 2px;
        }

        .th {
          @extend %secondary-font--bold;
        }

        .td {
          cursor: pointer;
          @extend %secondary-font;

          &.active, &.green, &.grey {
            color: white;
            border-radius: 20px;

            &.first {
              border-left: 1px solid var(--app-color);
              border-radius: 20px 0 0 20px;
            }

            &.active { background: var(--app-color); }
          }

          .number {
            border-radius: 20px;
            margin-top: -3px;
            height: calc(100% + 6px);
            display: flex;
            align-items: center;
            justify-content: center;

          }

          &.week {
            padding-right: 0;
            border-radius: 0 20px 20px 0;
            cursor: default;

            &.active {
              .number {
                background: var(--app-color);
                color: white;
              }
            }
          }
        }

        .prev-month-day, .next-month-day { color: #A6A6A6; }

        &::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 1rem;
          right: 3.9rem;
          width: 1px;
          height: calc(100% - 2.3rem);
          background-color: #CFCFCF;
        }
      }
    }
  }
</style>
