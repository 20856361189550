<template>
  <div :class="['geo-selection', { 'light-mode': lightMode }]">
    <div class="header">
      <div class="title">{{ $t(`geoNomSelection.geographic.title${ lightMode ? 'Min' : '' }`) }}</div>
      <div class="right">
        <div class="format-filters">
          <div v-for="format in formats" :key="format" :class="[{ selected: formatFilter.includes(format) }, 'filter']" @click="onFormatFilterClick(format)">
            <div class="box"><v-svg v-if="formatFilter.includes(format)" h=".7em" name="checks" /></div>
            {{ $t(`formats.${format}`) }}
          </div>
        </div>
        <div class="favorites-filter">
          <v-svg v-for="(type, i) in favTypes"
                 :key="`fav-filter-${i}`"
                 :class="{ active: activeFavoriteTypes.includes(type) }"
                 h="1.5rem"
                 :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
                 @click="onFavoriteFilterClicked(type)" />
        </div>
        <div class="search">
          <input v-model="searchTerm"
                 type="text"
                 class="input"
                 :placeholder="$t('geoNomSelection.geographic.placeholderResearch')">
          <v-svg h="1.5rem" name="loupe" />
        </div>
      </div>
    </div>
    <div id="levels" class="levels">
      <template v-if="activeFavoriteTypes.length">
        <template v-if="geoFavorites.length">
          <nomenclatureLevel v-for="dGeo in geoFavorites"
                             :key="dGeo.geoLevel"
                             :level="dGeo.geoLevel"
                             :values="dGeo[dGeo.childrenKey]"
                             :selectable="isGeoSelectable(dGeo)"
                             :selected-ids="selectedGeos.map(n => n[n.geoLevel])"
                             geo
                             :light-mode="lightMode"
                             @display="displayChildren($event)"
                             @select="selectGeo($event)" />
        </template>
        <div v-else class="empty-favorites">{{ $t('geoNomSelection.noElementsInFav') }}</div>
      </template>
      <template v-else>
        <template v-if="searchTerm.length < 3 && !activeFavoriteTypes.length">
          <template v-for="dGeo in displayedGeo">
            <nomenclatureLevel v-if="childrensByFormat(dGeo[dGeo.childrenKey]).length"
                               :key="dGeo.geoLevel"
                               :level="dGeo.geoLevel"
                               :displayed="displayedGeo[getGeoDepth(dGeo.geoLevel) + 1]"
                               :values="childrensByFormat(dGeo[dGeo.childrenKey])"
                               :selectable="isGeoSelectable(dGeo)"
                               :selected-ids="selectedGeos.map(n => n[n.geoLevel])"
                               geo
                               :light-mode="lightMode"
                               @display="displayChildren($event)"
                               @select="selectGeo($event)" />
            <img v-else
                 :key="dGeo.geoLevel"
                 style="width: 24rem; height: 23.4rem"
                 src="https://media3.giphy.com/media/VIQfHC9jAZbt6ojTdo/giphy.gif">
          </template>
        </template>
        <template v-else>
          <nomenclatureLevel v-for="dGeo in searchResult"
                             :key="dGeo.geoLevel"
                             :level="dGeo.geoLevel"
                             :values="dGeo[dGeo.childrenKey]"
                             :selectable="isGeoSelectable(dGeo)"
                             :selected-ids="selectedGeos.map(n => n[n.geoLevel])"
                             geo
                             :light-mode="lightMode"
                             @display="displayChildren($event)"
                             @select="selectGeo($event)" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import useContextStore from '@/store/context.store'
  import useReferentialStore from '@/store/referential.store'
  import { favoritesTypes } from '@/utils/CONF'
  import { computed, onMounted, ref } from 'vue'

  const formats = ['HYP', 'PRO']

  export default {
    name: 'GeoSelection',
    props: {
      initialValue: {
        type: Array,
        default: () => []
      },
      formats: {
        type: Array,
        default: () => []
      },
      contracts: {
        type: Array,
        default: () => []
      },
      unselectableLevels: {
        type: Array,
        default: () => []
      },
      uniqueSelection: {
        type: Boolean,
        default: false
      },
      lightMode: {
        type: Boolean,
        default: false
      }
    },
    emits: ['input'],

    setup(props, { emit }) {
      const referentialStore = useReferentialStore()
      const { geography, geoLevels, geoByLevel, getGeoDepth } = referentialStore

      const searchTerm = ref('')
      const displayedGeo = ref([])
      const selectedGeos = ref([])
      const geoLevel = ref(undefined)

      const formatFilter = ref(['HYP', 'PRO'])

      const removeAccents = (string = '') => {
        return `${ string }`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      }

      const searchResult = computed(() => {
        if (searchTerm.value.length < 3) return []
        const filterByFormat = props.formats.length === 1
        const filterByContracts = props.contracts.length === 1
        const levels = geoLevels.value.slice(3, 5).map(level => ({
          geoLevel: level,
          childrenKey: 'children',
          children: geoByLevel.value(level).filter(
            geo =>
              (removeAccents(geo[`${ geo.geoLevel }Label`]).includes(removeAccents(searchTerm.value)) || geo.site === searchTerm.value) &&
              (filterByFormat ? props.formats[0] === geo.siteFormat : true) &&
              (filterByContracts ? props.contracts[0] === geo.siteCurrentContract : true)
          )
        }))

        return levels.filter(l => l.children.length)
      })

      const isGeoSelectable = geo => {
        if (!geo[geo.childrenKey].length) return
        if (props?.unselectableLevels?.length && props?.unselectableLevels?.includes(geo[geo.childrenKey][0].geoLevel)) return false
        return geo[geo.childrenKey][0].geoLevel === geoLevel.value || !geoLevel.value
      }

      const selectGeo = geo => {
        if (!geoLevel.value) geoLevel.value = geo.geoLevel
        if (geoLevel.value === geo.geoLevel) {
          const index = selectedGeos.value.findIndex(n => n[n.geoLevel] === geo[geo.geoLevel])
          if (index < 0) {
            if (props.uniqueSelection) selectedGeos.value[0] = geo
            else selectedGeos.value.push(geo)
          } else {
            if (props.uniqueSelection) selectedGeos.value = []
            else selectedGeos.value.splice(index, 1)
          }
        }
        if (!selectedGeos.value.length) geoLevel.value = undefined
        else emit('input', selectedGeos.value)
      }

      const displayChildren = geo => {
        const children = geo[geo.childrenKey]
        const geoLevel = geo.geoLevel
        if (!children) return

        const geoDepth = getGeoDepth.value(geoLevel)
        if (geoDepth < 0) return
        else {
          if (displayedGeo.value.length - 1 >= geoDepth && displayedGeo.value[geoDepth][geoLevel] === geo[geoLevel]) return
          displayedGeo.value.splice(geoDepth)
          displayedGeo.value.push(geo)
        }

        setTimeout(() => {
          document.getElementById('levels').scrollBy({
            left: +1000,
            behavior: 'smooth'
          })
        }, 0)

        if (children.length === 1) displayChildren(children[0])
      }

      const childrensByFormat = children => {
        if (!children || !children.length || children?.[0]?.geoLevel !== 'site') return children
        const filterByFormat = props.formats.length === 1
        const filterByContracts = props.contracts.length === 1
        return children.filter(
          c => (filterByFormat ? props.formats[0] === c.siteFormat : true) && (filterByContracts ? props.contracts[0] === c.siteCurrentContract : true)
        )
      }

      const contextStore = useContextStore()
      const { getFavoritesByTypes } = contextStore

      const activeFavoriteTypes = ref([])

      const favTypes = ref(favoritesTypes)

      const onFavoriteFilterClicked = type => {
        const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
        isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)
      }

      const geoFavorites = computed(() => {
        let favorites = getFavoritesByTypes('geography', activeFavoriteTypes.value)

        if (searchTerm.value.length > 2) favorites = favorites.filter(fav => removeAccents(fav.siteLabel).includes(removeAccents(searchTerm.value)))

        const sites = geoByLevel.value('site')
        const sitesFav = favorites?.map(fav => sites.find(site => site.siteSid === fav.siteSid))
        return sitesFav.length ? [{ children: sitesFav, childrenKey: 'children', geoLevel: 'site' }] : []
      })

      const onFormatFilterClick = format => {
        if (formatFilter.value.length === 1 && formatFilter.value[0] === format) return

        if (formatFilter.value.length === 2) formatFilter.value = formatFilter.value.filter(filter => filter !== format)
        else formatFilter.value.push(format)

        displayedGeo.value = [{
          ...geography.value,
          territories: geography.value.territories.map(territory => {
            return {
              ...territory,
              livingAreas: territory.livingAreas.map(livingArea => {
                return {
                  ...livingArea,
                  sites: livingArea.sites.filter(site => formatFilter.value.includes(site.siteFormat))
                }
              })
            }
          })
        }]
      }

      onMounted(() => {
        displayedGeo.value = [geography.value]
        selectedGeos.value = props.initialValue
        geoLevel.value = undefined
      })

      return {
        getGeoDepth,
        searchTerm,
        displayedGeo,
        searchResult,
        selectedGeos,
        isGeoSelectable,
        selectGeo,
        displayChildren,
        childrensByFormat,
        activeFavoriteTypes,
        favTypes,
        onFavoriteFilterClicked,
        geoFavorites,
        formatFilter,
        onFormatFilterClick,
        formats
      }
    }
  }
</script>

<style scoped lang="scss">
  .geo-selection {
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    width: 100vw;
    z-index: 100;
    padding: 0 12.5%;
    background-color: white;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.6rem 1rem 1.6rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #A6A6A6;

      .title {
        @extend %primary-font--bold;
        font-size: $font-size-big;
      }

      .format-filters {
        display: flex;
        align-items: center;

        .filter {
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: $font-size-medium;

          &.selected {
            .box {
              background-color: var(--app-color);
              border: 1px solid transparent;
              color: white;
            }
          }

          .box {
            height: 24px;
            width: 24px;
            border: 1px solid rgba(0, 0, 0, 0.36);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            margin-right: 8px;

            .svg-checks { margin-top: -0.7rem; }
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .favorites-filter {
          display: flex;
          align-items: center;
          border-right: 2px solid #A6A6A6;
          border-left: 2px solid #A6A6A6;
          padding: 7px 12px;
          margin: 0 1.3rem;

          .v-svg {
            cursor: pointer;
            margin-right: 0.5rem;
          }
        }

        .search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 7px;
          padding: 9px 12px;

          input[type='text'] {
            width: 100px;
            border: none;
            outline: none;
            border-radius: 8px;
            font-size: $font-size-medium;
            @extend %secondary-font--bold;

            &.error {
              border: 1px solid #FF3526;
            }
          }

          .label {
            font-weight: 600;
          }
        }
      }
    }

    .empty-favorites {
      padding: 0.8rem;
    }

    .levels {
      display: flex;
      overflow-x: auto;
    }

    ::-webkit-scrollbar {
      background: none;
      width: 0.375rem;
    }

    ::-webkit-scrollbar:horizontal {
      background: none;
      height: 0.375rem;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--grey-light);
      border-radius: 1rem;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    &.light-mode {
      background-color: white;
      padding: 0;
      color: inherit;

      .header {
        padding: 0 0 0.5rem 0;
        border-bottom: none;
        margin-bottom: 0;

        .title {
          font-size: $font-size-small;
        }

        .right {
          position: absolute;
          right: 1rem;
          top: 0.5rem;
          background-color: white;
          z-index: 1;

          .favorites-filter {
            margin-right: 0;
            padding: 0 12px 0 0;

            .v-svg {
              margin: 0 0 0 0.5rem;
            }
          }


          .search {
            padding: 6px;

            .input {
              background-color: white;
              color: inherit;
              font-size: $font-size-small;
            }

            .svg-loupe { color: #CFCFCF; }
          }
        }
      }

      .levels {
        border: 1px solid rgb(207 207 207);
        border-radius: 7px;
        height: 92%;
      }
    }
  }
</style>
