export const allUniverses = {
  allUniverses: -1,
  allUniversesSid: -1,
  allUniversesLabel: 'Tous les univers',
  nomLevel: 'allUniverses',
  childrenKey: 'universes'
}

export const allGeo = { allGeo: -1, allGeoLabel: 'France', geoLevel: 'allGeo', childrenKey: 'territories' }

export const formats = ['HYP', 'DRI', 'GAS', 'PRO', 'DIF', 'HDE', 'ULT']

export const siteContracts = ['INT', 'FRA']

export const overviews = ['coefficient', 'evolutionPercentSalesQuantity', 'forecastSalesQuantity']

export const weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']

export const favoritesTypes = ['star', 'label', 'filter', 'shipping', 'bookmark']
