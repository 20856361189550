<template>
  <div :class="['nom-selection', { 'light-mode': lightMode }]">
    <div class="header">
      <div class="title">{{ $t(`geoNomSelection.nomenclature.title${ lightMode ? 'Min' : '' }`) }}</div>
      <div class="right">
        <div class="favorites-filter">
          <v-svg v-for="(type, i) in favTypes"
                 :key="`fav-filter-${i}`"
                 :class="{ active: activeFavoriteTypes.includes(type) }"
                 h="1.5rem"
                 :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
                 @click="onFavoriteFilterClicked(type)" />
        </div>
        <div class="search">
          <input v-model="searchTerm"
                 type="text"
                 class="input"
                 :placeholder="$t('geoNomSelection.nomenclature.placeholderResearch')">
          <v-svg h="1.5rem" name="loupe" />
        </div>
      </div>
    </div>
    <div id="levels" class="levels">
      <template v-if="activeFavoriteTypes.length">
        <template v-if="favDisplayedNomenclature.length">
          <nomenclatureLevel v-for="dnom in favDisplayedNomenclature"
                             :key="dnom.nomLevel"
                             :level="dnom.nomLevel"
                             :values="dnom[dnom.childrenKey]"
                             :selectable="isNomenclatureSelectable(dnom)"
                             :light-mode="lightMode"
                             :selected-ids="selectedNoms.map(n => n[n.nomLevel])"
                             @display="displayChildren($event, true)"
                             @select="selectNom($event)" />
        </template>
        <div v-else class="empty-favorites">{{ $t('geoNomSelection.noElementsInFav') }}</div>
      </template>
      <template v-else>
        <template v-if="searchTerm.length < 3">
          <nomenclatureLevel v-for="dnom in displayedNomenclature"
                             :key="dnom.nomLevel"
                             :level="dnom.nomLevel"
                             :displayed="displayedNomenclature[getNomenclatureDepth(dnom.nomLevel) + 1]"
                             :values="dnom[dnom.childrenKey]"
                             :selectable="isNomenclatureSelectable(dnom)"
                             :selected-ids="selectedNoms.map(n => n[n.nomLevel])"
                             :light-mode="lightMode"
                             @display="displayChildren($event)"
                             @select="selectNom($event)" />
        </template>
        <template v-else>
          <nomenclatureLevel v-for="dnom in searchResult"
                             :key="dnom.nomLevel"
                             :level="dnom.nomLevel"
                             :values="dnom[dnom.childrenKey]"
                             :selectable="isNomenclatureSelectable(dnom)"
                             :light-mode="lightMode"
                             :selected-ids="selectedNoms.map(n => n[n.nomLevel])"
                             @select="selectNom($event)" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import useContextStore from '@/store/context.store'
  import useReferentialStore from '@/store/referential.store'
  import { favoritesTypes } from '@/utils/CONF'
  import { computed, onMounted, ref } from 'vue'

  export default {
    name: 'NomSelection',
    props: {
      initialValue: {
        type: Array,
        default: () => []
      },
      unselectableLevels: {
        type: Array,
        default: () => []
      },
      uniqueSelection: {
        type: Boolean,
        default: false
      },
      lightMode: {
        type: Boolean,
        default: false
      }
    },
    emits: ['input'],
    setup(props, { emit }) {
      const referentialStore = useReferentialStore()
      const { nomenclature, nomenclatureLevels, nomenclatureByLevel, getNomenclatureDepth } = referentialStore

      const searchTerm = ref('')
      const displayedNomenclature = ref([])
      const favDisplayedNomenclature = ref([])
      const selectedNoms = ref([])
      const nomenclatureLevel = ref(undefined)

      const removeAccents = (string = '') => {
        return `${ string }`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      }

      const searchResult = computed(() => {
        if (searchTerm.value.length < 3) return []
        const levels = nomenclatureLevels.value.slice(1, 4).map(level => ({
          nomLevel: level,
          childrenKey: 'children',
          children: nomenclatureByLevel.value(level).filter(nom => removeAccents(nom[`${ nom.nomLevel }Label`]).includes(removeAccents(searchTerm.value)))
        }))

        return levels.filter(l => l.children.length)
      })

      const isNomenclatureSelectable = nom => {
        if (!nom[nom.childrenKey].length) return
        if (props?.unselectableLevels?.length && props?.unselectableLevels?.includes(nom[nom.childrenKey][0].nomLevel)) return false
        return nom[nom.childrenKey][0].nomLevel === nomenclatureLevel.value || !nomenclatureLevel.value
      }

      const selectNom = nom => {
        if (!nomenclatureLevel.value) nomenclatureLevel.value = nom.nomLevel
        const index = selectedNoms.value.findIndex(n => n[n.nomLevel] === nom[nom.nomLevel])
        if (index < 0) {
          if (props.uniqueSelection) selectedNoms.value[0] = nom
          else selectedNoms.value.push(nom)
        } else {
          if (props.uniqueSelection) selectedNoms.value = []
          else selectedNoms.value.splice(index, 1)
        }
        if (!selectedNoms.value.length) nomenclatureLevel.value = undefined
        else emit('input', selectedNoms.value)
      }

      const displayChildren = (nom, displayFav = false) => {
        const displayedNom = displayFav ? favDisplayedNomenclature : displayedNomenclature

        const children = nom[nom.childrenKey]
        const nomLevel = nom.nomLevel
        if (!children) return

        const nomDepth = displayFav ? 1 : getNomenclatureDepth.value(nomLevel)
        if (nomDepth < 0) return
        else {
          if (displayedNom.value.length - 1 >= nomDepth && displayedNom.value[nomDepth][nomLevel] === nom[nomLevel]) return
          displayedNom.value.splice(nomDepth)
          displayedNom.value.push(nom)
        }

        setTimeout(() => {
          document.getElementById('levels').scrollBy({
            left: +1000,
            behavior: 'smooth'
          })
        }, 0)

        if (children.length === 1) displayChildren(children[0])
      }

      const contextStore = useContextStore()
      const { getFavoritesByTypes } = contextStore

      const activeFavoriteTypes = ref([])

      const favTypes = ref(favoritesTypes)

      const onFavoriteFilterClicked = type => {
        const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
        isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)

        const favorites = getFavoritesByTypes('nomenclature', activeFavoriteTypes.value, true).filter(fav => fav.marketSid !== 1 && fav.marketSid !== 2)
        const markets = nomenclatureByLevel.value('market')
        const marketsFav = favorites?.map(fav => markets.find(market => market.marketSid === fav.marketSid))
        favDisplayedNomenclature.value = [{ children: marketsFav, childrenKey: 'children', geoLevel: 'market' }] ?? []
      }

      onMounted(() => {
        displayedNomenclature.value = [nomenclature.value]
        selectedNoms.value = props.initialValue
        nomenclatureLevel.value = props.initialValue?.[0]?.nomLevel
      })

      return {
        favDisplayedNomenclature,
        getNomenclatureDepth,
        searchTerm,
        displayedNomenclature,
        selectedNoms,
        nomenclatureLevel,
        searchResult,
        isNomenclatureSelectable,
        selectNom,
        displayChildren,
        activeFavoriteTypes,
        favTypes,
        onFavoriteFilterClicked
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nom-selection {
    position: fixed;
    top: 70px;
    left: 0;
    height: calc(100vh - 70px);
    width: 100vw;
    z-index: 100;
    padding: 0 12.5%;
    background-color: white;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.6rem 1rem 1.6rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid #A6A6A6;

      .title {
        @extend %primary-font--bold;
        font-size: $font-size-big;
      }

      .right {
        display: flex;
        align-items: center;

        .favorites-filter {
          display: flex;
          align-items: center;
          border-right: 2px solid #A6A6A6;
          padding: 7px 12px;
          margin-right: 1.3rem;

          .v-svg {
            cursor: pointer;
            margin-right: 0.5rem;
          }
        }

        .search {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 7px;
          padding: 9px 12px;

          input[type='text'] {
            width: 100px;
            border: none;
            outline: none;
            border-radius: 8px;
            font-size: $font-size-medium;
            @extend %secondary-font--bold;

            &.error {
              border: 1px solid #FF3526;
            }
          }

          .label {
            font-weight: 600;
          }
        }
      }
    }

    .empty-favorites {
      padding: 0.8rem;
    }

    .levels {
      display: flex;
      overflow-x: auto;
    }

    ::-webkit-scrollbar {
      background: none;
      width: 0.375rem;
    }

    ::-webkit-scrollbar:horizontal {
      background: none;
      height: 0.375rem;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--grey-light);
      border-radius: 1rem;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    &.light-mode {
      background-color: white;
      padding: 0;
      color: inherit;

      .header {
        padding: 0 0 0.5rem 0;
        border-bottom: none;
        margin-bottom: 0;

        .title {
          font-size: $font-size-small;
        }

        .right {
          position: absolute;
          right: 1rem;
          top: 0.5rem;
          background-color: white;

          .favorites-filter {
            margin-right: 0;
            padding: 0 12px 0 0;

            .v-svg {
              margin: 0 0 0 0.5rem;
            }
          }

          .search {
            padding: 6px;

            .input {
              background-color: white;
              color: inherit;
              font-size: $font-size-small;
            }

            .svg-loupe { color: #CFCFCF; }
          }
        }
      }

      .levels {
        border: 1px solid rgb(207 207 207);
        border-radius: 7px;
        height: 92%;
      }
    }
  }
</style>
