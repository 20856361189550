<template>
  <div class="date-picker-week">
    <div class="info-btn">
      <div class="title">{{ $t('datepicker.title') }}</div>
      <v-svg h="1.5rem" name="chevron-left" @click="shiftWeek(-1)" />
      <div class="label" @click="togglePicker()">{{ selectedPeriodToString }}</div>
      <v-svg h="1.5rem" name="chevron-right" @click="shiftWeek(1)" />
      <v-svg h="1.2rem" name="calendar" @click="togglePicker()" />
    </div>
    <div v-if="isOpen" class="picker">
      <div class="content">
        <div class="title">{{ $t('datepicker.selectTitle') }}</div>
        <v-svg h="0.8rem" name="cross" @click="togglePicker" />
        <div class="navigation">
          <div class="month selector">
            <v-svg h="1.5rem" name="chevron-left" @click="shiftMonthOrYear({ month: -1 })" />
            <div class="month label">{{ date.toFormat('LLLL') }}</div>
            <v-svg h="1.5rem" name="chevron-right" @click="shiftMonthOrYear({ month: 1 })" />
          </div>
          <div class="year selector">
            <v-svg h="1.5rem" name="chevron-left" @click="shiftMonthOrYear({ year: -1 })" />
            <div class="year label">{{ date.year }}</div>
            <v-svg h="1.5rem" name="chevron-right" @click="shiftMonthOrYear({ year: 1 })" />
          </div>
        </div>
        <div class="calendar">
          <div v-for="(dayLetter, i) of daysLetters" :key="i" class="th day">{{ dayLetter }}</div>
          <div class="th week">N°</div>
          <div v-for="(day, i) in emptyCells"
               :key="`previousMonthDay#${day}`"
               :class="[{ first: i === 0, active: selectedPeriod.weekNumber === date.plus({ month: -1 }).set({ day }).weekNumber && selectedPeriod.year === date.year }, 'td prev-month-day']">
            <div class="number">{{ day }}</div>
          </div>
          <template v-for="(day, i) of date.daysInMonth" :key="i">
            <div
              :class="[{ first: date.set({ day }).weekday === 1, active: selectedPeriod.weekNumber === date.set({ day }).weekNumber && selectedPeriod.year === date.year }, 'td day']">
              <div class="number">{{ day }}</div>
            </div>
            <div v-if="(emptyCells.length + i + 1) % 7 === 0"
                 :class="[{ active: selectedPeriod.weekNumber === date.set({ day }).weekNumber && selectedPeriod.year === date.year }, 'td week']"
                 @click="selectWeek(date.set({ day }).weekNumber)">
              <div class="number">{{ date.set({ day }).weekNumber }}</div>
            </div>
          </template>
          <div v-for="day in daysOfNextMonth"
               :key="`nextMonthDay#${day}`"
               :class="[{ first: daysOfNextMonth === 7 && day === 1, active: selectedPeriod.weekNumber === date.plus({ month: 1 }).weekNumber && selectedPeriod.year === date.year }, 'td next-month-day']">
            <div class="number">{{ day }}</div>
          </div>
          <div
            :class="[{ active: selectedPeriod.weekNumber === date.plus({ month: 1 }).weekNumber && selectedPeriod.year === date.year }, 'td week last']"
            @click="selectWeek(date.plus({ month: 1 }).weekNumber)">
            <div class="number">{{ date.plus({ month: 1 }).weekNumber }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DateTime, Info } from 'luxon'
  import { computed, ref } from 'vue'

  const daysLetters = Info.weekdays('short').map(day => day[0].toUpperCase())

  export default {
    name: 'DatePickerWeek',
    props: {
      selectedWeek: {
        type: Object,
        default: () => ({ weekNumber: DateTime.local().weekNumber, year: DateTime.local().year })
      }
    },
    emits: ['select'],
    setup(props, { emit }) {

      const isOpen = ref(false)

      const selectedPeriod = ref({ ...props.selectedWeek })

      const date = ref(DateTime.fromObject({
        weekYear: selectedPeriod.value.year,
        weekNumber: selectedPeriod.value.weekNumber
      }).set({ day: 1 }))

      const daysOfPreviousMonth = computed(() => date.value.minus({ months: 1 }).daysInMonth)

      const emptyCells = computed(() => {
        const cells = []
        for (let i = date.value.weekday - 1; i > 0; i--) cells.push(daysOfPreviousMonth.value - i + 1)
        return cells
      })

      const daysOfNextMonth = computed(() => 7 - ((date.value.daysInMonth + emptyCells.value.length) % 7))

      const selectedPeriodToString = computed(() => {
        const d = DateTime.fromObject({
          weekYear: selectedPeriod.value.year,
          weekNumber: selectedPeriod.value.weekNumber
        })

        return `${ d.startOf('week').toFormat('EEE dd') } - ${ d.endOf('week').toFormat('EEE dd MMMM yyyy') } / Semaine ${ selectedPeriod.value.weekNumber }`
      })

      const shiftMonthOrYear = ({ year = 0, month = 0 }) => date.value = date.value.plus({ year, month })

      const shiftWeek = week => {
        const newDate = DateTime.fromObject({
          weekYear: selectedPeriod.value.year,
          weekNumber: selectedPeriod.value.weekNumber
        }).plus({ week })

        selectedPeriod.value.weekNumber = newDate.weekNumber
        selectedPeriod.value.year = newDate.year

        emit('select', { ...selectedPeriod.value })
      }

      const selectWeek = weekNumber => {
        selectedPeriod.value.weekNumber = weekNumber
        selectedPeriod.value.year = date.value.year
      }

      const togglePicker = () => {
        isOpen.value = !isOpen.value
        if (!isOpen.value && (props.selectedWeek.weekNumber !== selectedPeriod.value.weekNumber || props.selectedWeek.year !== selectedPeriod.value.year))
          emit('select', { ...selectedPeriod.value })
      }

      return {
        isOpen,
        daysLetters,
        date,
        emptyCells,
        shiftMonthOrYear,
        daysOfNextMonth,
        selectedPeriod,
        selectWeek,
        shiftWeek,
        selectedPeriodToString,
        togglePicker
      }
    }
  }
</script>

<style scoped lang="scss">
  .date-picker-week {
    position: relative;
    width: 400px;
    user-select: none;

    .info-btn {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--app-color);
      border: 1px solid rgba(158, 81, 33, 0.5);
      padding: 4px 9px 3px 5px;
      border-radius: 7px;
      font-size: 12px;
      width: fit-content;
      margin-left: auto;
      text-transform: capitalize;

      .title {
        position: absolute;
        top: -10px;
        background-color: white;
        padding: 0 3px;
        @extend %secondary-font;
        font-size: $font-size-tiny;
      }

      .label {
        @extend %secondary-font--bold;
        font-size: $font-size-small;
        cursor: pointer;
      }

      .v-svg { cursor: pointer; }
    }

    .picker {
      position: absolute;
      margin: 25px 0 0 25px;
      width: 420px;
      background-color: white;
      border-radius: 16px;
      border: 1px solid #CFCFCF;
      z-index: 9999999;
      padding: 1.5rem 1rem 0 1rem;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
      }

      &::before {
        top: -22px;
        right: 54px;
        border-color: transparent transparent #CFCFCF transparent;
        border-width: 11px;
      }

      &::after {
        top: -19px;
        right: 55.5px;
        border-color: transparent transparent white transparent;
        border-width: 10px;
      }
    }

    .content {
      position: relative;
      height: 95%;
      width: 100%;
      border-radius: 16px;
      border: 1px solid #CFCFCF;
      margin-bottom: 1rem;

      .title {
        position: absolute;
        top: -10px;
        left: 5px;
        padding: 0 0.5rem;
        background-color: white;
        font-size: $font-size-title;
        @extend %primary-font--bold;
      }

      .svg-cross {
        position: absolute;
        top: -8px;
        right: 12px;
        padding: 0 0.5rem;
        cursor: pointer;
        background-color: white;
      }

      .navigation {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1.5rem;
        @extend %secondary-font--bold;
        font-size: $font-size-medium;

        .selector {
          display: flex;
          align-items: center;

          .label { margin: 0 1rem; }
        }

        .v-svg { cursor: pointer; }

        .month { text-transform: capitalize; }
      }

      .calendar {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        padding: 1rem 1.2rem;
        row-gap: 0.5rem;
        position: relative;

        .th, .td {
          text-align: center;
          padding: 2px;
        }

        .th {
          @extend %secondary-font--bold;
        }

        .td {
          @extend %secondary-font;

          &.active {
            border-top: 1px solid var(--app-color);
            border-bottom: 1px solid var(--app-color);

            &.first {
              border-left: 1px solid var(--app-color);
              border-radius: 20px 0 0 20px;
            }
          }

          .number {
            border-radius: 20px;
            margin-top: -3px;
            height: calc(100% + 6px);
            display: flex;
            align-items: center;
            justify-content: center;

            &.green, &.grey { color: white; }

            &.green { background-color: rgba(55, 139, 41, 1); }

            &.grey { background-color: rgba(128, 128, 128, 1); }
          }

          &.week {
            cursor: pointer;
            padding-right: 0;
            border-radius: 0 20px 20px 0;

            &.active {
              .number {
                background: var(--app-color);
                color: white;
              }
            }
          }
        }

        .prev-month-day, .next-month-day {
          color: #A6A6A6;

          .number {
            &.grey, &.green { opacity: 0.5; }
          }
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          bottom: 1rem;
          right: 3.9rem;
          width: 1px;
          height: calc(100% - 2.3rem);
          background-color: #CFCFCF;
        }
      }
    }
  }
</style>
