<template>
  <div class="app-nav">
    <div class="left">
      <v-svg h="3.6rem" name="logo" />

      <div v-if="$route.name === 'Nomenclature'"
           :class="[{'selected-geo': !selectedGeoIsEmpty} ,'geo-selection']"
           @click="showGeoNom.geo = !showGeoNom.geo">
        <v-svg h="1.5rem" name="list" />
        <div v-if="selectedGeoIsEmpty" class="label">{{ $t('appNav.geoSelection') }}</div>
        <div v-else class="label">{{ geography[0].siteLabel }}</div>
        <v-svg h="1.5rem" name="chevron-bottom" />
      </div>
      <div v-if="$route.name === 'Geographic'"
           :class="[{'selected-nom': !selectedNomIsEmpty} ,'nom-selection']"
           @click="showGeoNom.nom = !showGeoNom.nom">
        <v-svg h="1.5rem" name="list" />
        <div v-if="selectedNomIsEmpty" class="label">{{ $t('appNav.nomSelection') }}</div>
        <div v-else class="label">{{ nomenclature[0][`${ nomenclature[0].nomLevel }Label`] }}</div>
        <v-svg h="1.5rem" name="chevron-bottom" />
      </div>
      <div v-if="$route.name === 'DoubleRestocking'" class="blank" />
    </div>

    <div class="middle">
      <div class="views">
        <div v-tooltip="$t('appNav.nomTooltip')"
             :class="[{active : $route.name === 'Nomenclature'}, 'menu-icon']"
             @click="navigate({ name: 'Nomenclature', query: {...$route.query} })">
          <v-svg h="1.5rem" name="store" />
        </div>
        <div v-tooltip="$t('appNav.geoTooltip')"
             :class="[{active: $route.name === 'Geographic'}, 'menu-icon']"
             @click="navigate({ name: 'Geographic', query: {...$route.query} })">
          <v-svg h="1.5rem" name="map" />
        </div>
        <div v-tooltip="$t('appNav.drTooltip')"
             :class="[{active: $route.name === 'DoubleRestocking'}, 'menu-icon']"
             @click="navigate({ name: 'DoubleRestocking', query: {...$route.query} })">
          <v-svg h="1.5rem" name="calendar" />
        </div>
      </div>

      <date-picker :show-dr="true" @select="setPeriod" />
    </div>

    <div class="right">
      <a class="tool" href="https://chat.google.com/room/AAAA5azng1A?cls=7" target="_blank">
        <v-svg h="1.2rem" name="chat" />
        CONTACT
      </a>
      <div class="tool">
        <notification :user="user" />
        NOTIFICATIONS
      </div>
      <div class="tool">
        <smart-galaxy :user="user" />
        PRODUITS
      </div>
    </div>
  </div>

  <geo-selection v-if="showGeoNom.geo"
                 :initial-value="geography"
                 :formats="selectedFormats"
                 :contracts="selectedContracts"
                 :unselectable-levels="['territory', 'livingArea']"
                 :unique-selection="true"
                 @input="selectGeo" />

  <nom-selection v-if="showGeoNom.nom"
                 :initial-value="nomenclature"
                 :unique-selection="true"
                 :unselectable-levels="['univers', 'department']"
                 @input="selectNom" />
</template>

<script>
  import { SmartGalaxy } from '@smartapp/smart-component'
  import useContextStore from '@/store/context.store'
  import { computed, ref, inject } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { $t } from '../i18n'

  export default {
    name: 'AppNav',
    components: { SmartGalaxy },
    setup() {
      const contextStore = useContextStore()
      const { geography, nomenclature, setSelectedNom, setSelectedGeo, setSelectedPeriod } = contextStore
      const router = useRouter()
      const route = useRoute()
      const user = inject('user')

      const showGeoNom = ref({ geo: false, nom: false })
      const selectedFormats = ref([])
      const selectedContracts = ref(['INT', 'FRA'])

      const selectGeo = geo => {
        setSelectedGeo(geo)
        showGeoNom.value = { geo: false, nom: false }
      }

      const selectedGeoIsEmpty = computed(() => !geography.value?.length)

      const selectNom = nom => {
        setSelectedNom(nom)
        showGeoNom.value = { geo: false, nom: false }
      }

      const selectedNomIsEmpty = computed(() => !nomenclature.value?.length)

      const navigate = ({ name, query }) => {
        if (route.name === name) return
        showGeoNom.value = { geo: false, nom: false }
        router.push({ name, query })
      }

      let debounceTimeout = null

      const setPeriod = period => {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => setSelectedPeriod(period), 500)
      }

      return { showGeoNom, user, geography, nomenclature, selectedFormats, selectedContracts, selectGeo, selectNom, selectedGeoIsEmpty, selectedNomIsEmpty, setPeriod, navigate }
    }
  }
</script>

<style lang="scss">
  .smart-galaxy {
    height: 20px;
  }

  .smart-galaxy-app-btn {
    height: 16px;
  }

  .smart-galaxy-title {
    font-size: $font-size-medium;
  }
</style>

<style scoped lang="scss">
  .app-nav {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    padding-left: 40px;
    border-radius: 0 0 10px 10px;

    .left, .middle, .right {
      height: 100%;
      display: flex;
      align-items: center;

      .svg-question, .svg-chat, .svg-apps { cursor: pointer; }
    }

    .left {
      .geo-selection, .nom-selection {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;
        @extend %primary-font--bold;
        font-size: $font-size-medium;

        .label {
          margin: 0.1rem 0.5rem 0 0.5rem;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 225px;
          white-space: nowrap;
        }
      }

      .blank {
        width: 300px;
        max-width: 300px;
      }

      .selected-geo, .selected-nom {
        color: black;
        background-color: white;

        .label {
          text-transform: lowercase;
          white-space: nowrap;
        }

        .label:first-letter { text-transform: uppercase; }

        .v-svg {
          color: black
        }
      }
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 55%;

      .views {
        display: flex;
        align-items: center;
        height: 100%;

        .menu-icon {
          padding: 15px 18px;
          cursor: pointer;
          position: relative;
          color: #404040;

          &.active {
            color: var(--app-color);

            &::before {
              position: absolute;
              bottom: 0;
              width: 25px;
              height: 3px;
              display: block;
              background: var(--app-color);
              content: '';
              border-radius: 10px 10px 0px 0px;
            }
          }
        }
      }
    }

    .right {
      background: linear-gradient(269.84deg, #BC8A5F 0.13%, rgba(188, 138, 95, 0.7) 34.94%, rgba(188, 138, 95, 0.5) 66.64%, rgba(188, 138, 95, 0.2) 83.26%, rgba(188, 138, 95, 0.05) 93.14%, rgba(188, 138, 95, 0) 99.37%);
      width: 35%;
      justify-content: flex-end;

      .tool {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 40px;
        color: white;
        font-size: 10px;
        cursor: pointer;
        @extend %primary-font;

        .v-svg { margin-bottom: 0.2rem; }
      }
    }
  }
</style>
