<template>
  <div class="more-apps">
    <button @click="showMoreApps = !showMoreApps">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path fill="currentColor" d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
      </svg>
    </button>
    <div class="label" @click="showMoreApps = !showMoreApps">PRODUITS</div>
    <transition name="pop">
      <div v-if="showMoreApps" class="more-apps-popin">
        <div v-for="(category, i) of apps" :key="i" class="app-category">
          <div class="title">
            {{ category.label }}
          </div>
          <div class="app-list">
            <a v-for="app of category.applications"
               :key="app.name"
               class="application"
               :href="app.link"
               target="_blank">
              <div class="img-container">
                <img :src="`/img/apps-logos/${app.logo}`">
              </div>
              <div class="label">
                {{ app.name }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'MoreApps',
    data() {
      return {
        showMoreApps: false,
        apps: [
          {
            label: '📈 Pilotage',
            applications: [
              { name: `Pilot`, logo: 'pilot.svg', link: 'https://pilot.auchan.fr' },
              { name: `Pilot'Stock`, logo: 'pilotstock.png', link: 'https://stocks.data.fr.auchan.com/#/' },
              { name: `Pilot'Promo`, logo: 'pilotpromo.png', link: 'http://promo.fr.auchan.com/home' }
            ]
          },
          {
            label: '🦊 Smart Suite',
            applications: [
              { name: `Smart Live`, logo: 'smartlive.png', link: 'https://live.pilot.auchan.fr/' },
              { name: `Smart Business`, logo: 'smartbusiness.png', link: 'https://smartbusiness.data.fr.auchan.com/' },
            ]
          },
          {
            label: '🕵️ Data Suite',
            applications: [
              { name: `DataStore`, logo: 'datastore.svg', link: 'https://datastore.auchan.fr' },
              { name: `DataPerf`, logo: 'dataperf.svg', link: 'https://dataperf.fr.auchan.com' },
              { name: `DataCamp`, logo: 'datacamp.svg', link: 'https://datacamp.fr.auchan.com' }
            ]
          }
        ]
      }
    },

    watch: {
      showMoreApps() {
        if (this.showMoreApps) window.addEventListener('click', this.clickOutside)
        else window.removeEventListener('click', this.clickOutside)
      }
    },
    methods: {
      clickOutside(e) {
        this.showMoreApps = !!this.$el.contains(e.target)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .more-apps {
    color: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      color: inherit;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .more-apps-popin {
      z-index: 30;
      color: black;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      border-radius: 4px;
      padding-bottom: 14px;
      font-size: 14px;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

      .app-category {
        padding: 16px 16px 0 16px;

        .title {
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 3px 3px 0 0;
          padding: 5px 5px 5px 10px;
        }

        .app-list {
          display: flex;

          .application {
            margin: 6px 12px;
            min-width: 60px;
            transition: all 0.1s ease-out;

            .img-container {
              padding: 5px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                height: 100%;
                border-radius: 4px;
                transition: all 0.4s ease-out;
              }
            }

            .label {
              padding-top: 5px;
              line-height: 10px;
              text-align: center;
              cursor: pointer;
              width: 100%;
            }

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
  .pop-enter-active {
    animation: popup-scale-y 310ms;
    transform-origin: top right;
  }

  .pop-leave-active {
    animation: popup-scale-y 310ms reverse;
    transform-origin: top right;
  }

  .pop-enter,
  .pop-leave-to {
    transform: scale(0);
  }

  .pop-enter-to,
  .pop-leave {
    transform: scale(1);
  }

  @keyframes popup-scale-y {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.05);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
</style>
